import React from 'react';
import './FloatingButton.css'; // Import the CSS file

const FloatingButton = ({ onClick }) => {
  return (
    <button className="floating-btn" onClick={onClick}>
      Take Demo
    </button>
  );
};

export default FloatingButton;
