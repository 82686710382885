import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import AboutUs from "../../components/AboutUs/AboutUs";

const AboutUsPage = () => {
  return (
    <div className="blog-page">
      <NavBar />
      <AboutUs />

      <Footer />
    </div>
  );
};

export default AboutUsPage;
