import React, { useState } from 'react';
import './ContactForm.css';
import contact from '../../assets/images/contact-2.jpg';
import Swal from 'sweetalert2';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const googleScriptUrl = "https://script.google.com/macros/s/AKfycbztOjN2Ykq3FHfDDl9xn24ERB223Tknp4DPBPPRvgkHzA8vuJ8pRmpGIvHV-GzK-a3AmQ/exec";

      const data = new FormData();
      data.append("fullName", formData.fullName);
      data.append("email", formData.email);
      data.append("phoneNumber", formData.phoneNumber); 
      data.append("message", formData.message);
      data.append("sheetName", "Contact"); 

      const response = await fetch(googleScriptUrl, {
        method: "POST",
        body: data
      });

      const result = await response.json();

      if (result.result === "success") {
        Swal.fire({
          title: 'Success!',
          text: 'Thanks for contacting us. We will get back to you soon.',
          icon: 'success',
          confirmButtonText: 'OK',
          width: '80%',
          customClass: {
            popup: 'responsive-swal-popup', 
          }
        });

        setFormData({
          fullName: '',
          email: '',
          phoneNumber: '', 
          message: ''
        });
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error submitting the form", error);
      Swal.fire({
        title: 'Error!',
        text: 'Error submitting the form. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
        width: '80%',
        customClass: {
          popup: 'responsive-swal-popup', 
        }
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-section" id="contact">
      <div className="contact-container">
        <div className="contact-title">
          <h2 className="h2">Contact <span className="section-title .span">Us</span></h2>
          <p>Ready to become a global leader and start your English language journey? <br />Don't wait! Contact us now to take the first step to mastering English</p>
        </div>
        <div className="contact-content">
          <div className="contact-image">
            <img src={contact} alt="Contact Us" />
          </div>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="fullName">Full name</label>
              <input type="text" id="fullName" name="fullName" value={formData.fullName} onChange={handleChange} required />
            </div>
            <div className="form-group-grouped">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input type="tel" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="message">Leave a Message</label>
              <input type="text" id="message" name="message" value={formData.message} onChange={handleChange} required />
            </div>
            <button type="submit" className="btn1" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Send"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
